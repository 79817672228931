import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const PartnersPage = () => (
  <Layout pageInfo={{ pageName: "partners" }}>
    <SEO title="Partners" />
    <h3>[stay tuned...]</h3>
  </Layout>
)

export default PartnersPage